<template>
  <div style="height: 100%;">
    <simple-date-header :date="translations.longTermPlanner">
      <!-- left side options -->
      <!-- <template slot="left-side-options">
      </template> -->

      <!-- right side options -->
      <template slot="right-side-options">
        <div style="position: absolute; right: 12px; top: 12px;" class="d-flex align-center">
          <!-- report dialog -->
          <report-dialog :reports="exportReports" class="mr-10"></report-dialog>

          <!-- planner view -->
          <v-btn @click="planner = 'longTerm'" class="mr-2" :size="28" icon :color="planner === 'longTerm' ? 'main' : ''"
            ><v-icon>{{ icons.mdiCalendarText }}</v-icon></v-btn
          >
          <v-btn @click="planner = 'globalPlanner'" :size="28" icon :color="planner === 'globalPlanner' ? 'main' : ''"
            ><v-icon>{{ icons.mdiCalendarCheck }}</v-icon>
          </v-btn>
        </div>
      </template>
    </simple-date-header>

    <template v-if="mountPlanner">
      <long-term-planner
        v-if="planner === 'longTerm'"
        :productionCenterList="productionCenterList"
        :activeProductionCenters="activeProductionCenters"
        :canReadOnly="canReadOnly"
        @remove-production-center="removeProductionCenter"
        @set-active-centers="setActiveCenters"
      ></long-term-planner>

      <global-planner
        v-else-if="planner === 'globalPlanner'"
        :productionCenterList="productionCenterList"
        :activeProductionCenters="activeProductionCenters"
        @remove-production-center="removeProductionCenter"
        @set-active-centers="setActiveCenters"
      ></global-planner>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SimpleDateHeader from "@/components/SimpleDateHeader.vue";
import ReportDialog from "@/components/Report/ReportDialog.vue";
const LongTermPlanner = () => import("./LongTermPlanner/LongTermPlanner.vue");
const GlobalPlanner = () => import("./GlobalPlanner/GlobalPlanner.vue");
import { mdiCalendarText, mdiCalendarCheck } from "@mdi/js";
import { translations } from "@/utils/common";
import getProductionCenterListQuery from "@/graphql/GetProductionCenterList.gql";

export default {
  name: "PlannerView",

  components: {
    SimpleDateHeader,
    ReportDialog,
    LongTermPlanner,
    GlobalPlanner
  },

  apollo: {
    getProductionCenterList: {
      query: getProductionCenterListQuery,
      fetchPolicy: "network-only",
      result({ data }) {
        const { getProductionCenterList } = data;
        this.productionCenterList = getProductionCenterList;
        this.activeProductionCenters = getProductionCenterList;

        this.mountPlanner = true;
      }
    }
  },

  data: () => ({
    planner: "longTerm",
    productionCenterList: [],
    activeProductionCenters: [],
    mountPlanner: false
  }),

  methods: {
    removeProductionCenter(productionCenter) {
      this.activeProductionCenters = this.activeProductionCenters.filter(pc => pc !== productionCenter);
    },

    setActiveCenters(productionCenters) {
      this.activeProductionCenters = productionCenters;
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),

    translations: () => translations,

    exportReports() {
      return [
        {
          name: "LongTermForeman",
          icon: "mdi-account-settings",
          params: {
            accountId: this.userInfo?.account?.id
          }
        }
      ];
    },

    icons: () => ({ mdiCalendarText, mdiCalendarCheck }),

    ...mapState({
      canReadOnly: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Read-only')
    })
  }
};
</script>

<style>
.vis-panel.vis-vertical-scroll::-webkit-scrollbar {
  width: 17px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.vis-panel.vis-vertical-scroll::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.vis-panel.vis-vertical-scroll::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.vis-panel.vis-vertical-scroll::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.vis-left.vis-panel.vis-vertical-scroll {
  direction: ltr;
}

.production-center-select > .v-input > .v-input__control > .v-input__slot > .v-select__slot {
  height: 30px !important;
  max-height: 30px !important;
}
</style>
